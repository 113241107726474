<template>
  <div class="costing-contrast">
<!--    <van-sticky>-->
      <div class="title">
        <van-row>
          <van-col offset="0" span="10">
            <div style="border-left: #2CD79F solid 2px; font-weight: bolder">
              计算结果对比页面
            </div>
          </van-col>
        </van-row>
      </div>
      <van-divider/>
      <van-row v-if="this.caculateItems">
        <van-field
            readonly
            clickable
            label="计算结果："
            clearable
            filterable
            :value="selCaculateValue1"
            placeholder="请选择计算结果"
            @click="showCaculatePicker = true"
        >
          <template #input>
            <el-select class="sel" v-model="selCaculateValue" multiple placeholder="请选择计算结果">
              <el-option
                  v-for="item in caculateItems"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </van-field>
      </van-row>
<!--    </van-sticky>-->
    <van-divider/>

    <div class="table-content">
      <table ref="table" class="table">
        <!-- 表头 -->
        <tr class="top">
          <th v-for="(item_th, index_th) in thList" :key="index_th" :style="{ background: item_th.backgroundColor }"
              :show-overflow-tooltip="true">
            <el-tooltip class="item" effect="dark" :content="item_th.name" placement="bottom">
              <span class="title">{{ item_th.name | ellipsis }}</span>
            </el-tooltip>
          </th>
        </tr>
        <!--展示列表数据 -->
        <tr class="cont">
          <td style="text-align: left">批次号</td>
          <td v-for="(item_tr, index_tr) in detail.batchNo" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">航次成本</td>
          <td v-for="(item_tr, index_tr) in detail.costVoyage" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">经营成本</td>
          <td v-for="(item_tr, index_tr) in detail.costBus" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">吨位</td>
          <td v-for="(item_tr, index_tr) in detail.dwt" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">单吨造价</td>
          <td v-for="(item_tr, index_tr) in detail.costTon" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">总造价</td>
          <td v-for="(item_tr, index_tr) in detail.costAll" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">剩余造价成本</td>
          <td v-for="(item_tr, index_tr) in detail.costResidue" :key="index_tr" style="text-align: left">
            {{ item_tr.value }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">已收益造价</td>
          <td v-for="(item_tr, index_tr) in detail.costRecover" :key="index_tr" style="text-align: left">
            {{ item_tr.value }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">剩余成本年回报率</td>
          <td v-for="(item_tr, index_tr) in detail.returnYy" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">月管理费</td>
          <td v-for="(item_tr, index_tr) in detail.costManage" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">重油耗</td>
          <td v-for="(item_tr, index_tr) in detail.heavyOil" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">轻油耗</td>
          <td v-for="(item_tr, index_tr) in detail.lightOil" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">重油价</td>
          <td v-for="(item_tr, index_tr) in detail.costHeavy" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">轻油价</td>
          <td v-for="(item_tr, index_tr) in detail.costLight" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">航行单天</td>
          <td v-for="(item_tr, index_tr) in detail.costSail" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">抛锚单天</td>
          <td v-for="(item_tr, index_tr) in detail.costDrop" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">航线</td>
          <td v-for="(item_tr, index_tr) in detail.routeName" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">货种</td>
          <td v-for="(item_tr, index_tr) in detail.goodsType" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">海里数</td>
          <td v-for="(item_tr, index_tr) in detail.nauticalMiles" :key="index_tr" style="text-align: left">
            {{ item_tr.value }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">两港单吨费用</td>
          <td v-for="(item_tr, index_tr) in detail.costPorts" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">航速</td>
          <td v-for="(item_tr, index_tr) in detail.speed" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">装卸天数</td>
          <td v-for="(item_tr, index_tr) in detail.layDay" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>
        <tr class="cont">
          <td style="text-align: left">航行天数</td>
          <td v-for="(item_tr, index_tr) in detail.voyageDays" :key="index_tr" style="text-align: left">{{
              item_tr.value
            }}
          </td>
        </tr>

      </table>
    </div>
  </div>
</template>

<script>
import {getDisCosting, getContrastCosting} from '@/api/costing'
import "mobile-table/lib/style.css";
import {Toast} from "vant";

export default {
  name: "CostingContrast",
  components: {},
  data() {
    return {
      // 表头列表
      thList: [
        {
          id: '',
          name: '名称'
        }
      ],
      tr: [
        '批次号',
        '航次成本',
        '经营成本',
        '吨位',
        '单吨造价',
        '总造价',
        '剩余造价成本',
        '已收益造价',
        '剩余成本年回报率',
        '月管理费',
        '重油耗',
        '轻油耗',
        '重油价',
        '轻油价',
        '航行单天',
        '抛锚单天',
        '航线',
        '货种',
        '海里数',
        '两港单吨费用',
        '航速',
        '装卸天数',
        '航行天数',
      ],
      detail: {
        batchNo: [],
        dwt: [],
        costTon: [],
        costAll: [],
        costResidue: [],
        costRecover: [],
        returnYy: [],
        costManage: [],
        heavyOil: [],
        lightOil: [],
        costHeavy: [],
        costLight: [],
        costSail: [],
        costDrop: [],
        routeName: [],
        goodsType: [],
        nauticalMiles: [],
        costPorts: [],
        speed: [],
        layDay: [],
        voyageDays: [],
        costVoyage: [],
        costBus: []
      },
      // 是否需要排序 默认不进行排序
      isNeedSort: false,
      // 排序序号
      sortIndex: null,
      // 排序形式 倒叙默认倒叙 false为正序
      order: true,
      // 是否显示更多
      showMore: this.showMoreCom,
      selCaculateValue: [],
      selCaculateValue1: '',
      caculateItems: [],
    }
  },
  watch: {
    'selCaculateValue': function () {
      let value = '';
      if (this.selCaculateValue.length > 0) {
        for (let i = 0; i < this.selCaculateValue.length; i++) {
          value += "'" + this.selCaculateValue[i] + "'" + ','
        }
        if (value.length > 0) {
          value = value.substring(0, value.length - 1)
        }
      } else {
        value = "'" + "'"
      }
      getContrastCosting({
        buscomparisonName: value,
      }).then(res => {
        this.thList = [
          {
            id: '',
            name: '名称'
          }
        ];
        this.detail = {
          batchNo: [],
          dwt: [],
          costTon: [],
          costAll: [],
          costResidue: [],
          costRecover: [],
          returnYy: [],
          costManage: [],
          heavyOil: [],
          lightOil: [],
          costHeavy: [],
          costLight: [],
          costSail: [],
          costDrop: [],
          routeName: [],
          goodsType: [],
          nauticalMiles: [],
          costPorts: [],
          speed: [],
          layDay: [],
          voyageDays: [],
          costVoyage: [],
          costBus: []
        };
        let arr = [];
        for (var i = 0; i < res.length; i++) {
          arr.push(res[i].buscomparisonName)
          let ar = {}
          ar.name = res[i].buscomparisonName
          ar.isSort = true
          ar.backgroundColor = '#fdeeee'
          this.thList.push(ar)

          let ar0 = {}
          ar0.name = res[i].buscomparisonName
          ar0.value = res[i].batchNo
          this.detail.batchNo.push(ar0)

          let ar1 = {}
          ar1.name = res[i].buscomparisonName
          ar1.value = res[i].dwt
          this.detail.dwt.push(ar1)

          let ar2 = {}
          ar2.name = res[i].buscomparisonName
          ar2.value = res[i].costTon
          this.detail.costTon.push(ar2)

          let ar3 = {}
          ar3.name = res[i].buscomparisonName
          ar3.value = res[i].costAll
          this.detail.costAll.push(ar3)

          let ar4 = {}
          ar4.name = res[i].buscomparisonName
          ar4.value = res[i].costResidue
          this.detail.costResidue.push(ar4)

          let ar5 = {}
          ar5.name = res[i].buscomparisonName
          ar5.value = res[i].costRecover
          this.detail.costRecover.push(ar5)

          let ar6 = {}
          ar6.name = res[i].buscomparisonName
          ar6.value = res[i].returnYy
          this.detail.returnYy.push(ar6)

          let ar7 = {}
          ar7.name = res[i].buscomparisonName
          ar7.value = res[i].costManage
          this.detail.costManage.push(ar7)

          let ar8 = {}
          ar8.name = res[i].buscomparisonName
          ar8.value = res[i].heavyOil
          this.detail.heavyOil.push(ar8)

          let ar9 = {}
          ar9.name = res[i].buscomparisonName
          ar9.value = res[i].lightOil
          this.detail.lightOil.push(ar9)

          let ar10 = {}
          ar10.name = res[i].buscomparisonName
          ar10.value = res[i].costHeavy
          this.detail.costHeavy.push(ar10)

          let ar11 = {}
          ar11.name = res[i].buscomparisonName
          ar11.value = res[i].costLight
          this.detail.costLight.push(ar11)

          let ar12 = {}
          ar12.name = res[i].buscomparisonName
          ar12.value = res[i].costSail
          this.detail.costSail.push(ar12)

          let ar13 = {}
          ar13.name = res[i].buscomparisonName
          ar13.value = res[i].costDrop
          this.detail.costDrop.push(ar13)

          let ar14 = {}
          ar14.name = res[i].buscomparisonName
          ar14.value = res[i].routeName
          this.detail.routeName.push(ar14)

          let ar15 = {}
          ar15.name = res[i].buscomparisonName
          ar15.value = res[i].goodsType
          this.detail.goodsType.push(ar15)

          let ar16 = {}
          ar16.name = res[i].buscomparisonName
          ar16.value = res[i].nauticalMiles
          this.detail.nauticalMiles.push(ar16)

          let ar17 = {}
          ar17.name = res[i].buscomparisonName
          ar17.value = res[i].costPorts
          this.detail.costPorts.push(ar17)

          let ar18 = {}
          ar18.name = res[i].buscomparisonName
          ar18.value = res[i].speed
          this.detail.speed.push(ar18)

          let ar19 = {}
          ar19.name = res[i].buscomparisonName
          ar19.value = res[i].layDay
          this.detail.layDay.push(ar19)

          let ar20 = {}
          ar20.name = res[i].buscomparisonName
          ar20.value = res[i].voyageDays
          this.detail.voyageDays.push(ar20)

          let ar21 = {}
          ar21.name = res[i].buscomparisonName
          ar21.value = res[i].costVoyage
          this.detail.costVoyage.push(ar21)

          let ar22 = {}
          ar22.name = res[i].buscomparisonName
          ar22.value = res[i].costBus
          this.detail.costBus.push(ar22)
        }
        console.log(this.thList)

      }).catch(err => {
        console.log(err)
      })
    },
  },
  filters: {
    // 过滤器filter,不允许超过10个长度
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 5) {
        return value.slice(0, 5) + '...'
      }
      return value
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getDisCosting({
        buscomparisonName: '',
      }).then(res => {
        this.caculateItems = []
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.label = res[i].buscomparisonName
          array.value = res[i].buscomparisonName
          this.caculateItems.push(array)
        }
        console.log(this.caculateItems)
      }).catch(err => {
        console.log(err)
      })
    },
    // 点击排序处理排序箭头 然后调用排序方法 需要处理防抖
    needSort(sortField, index) {
      // 排序完成的数据 再次点击排序就恢复初始值
      if (this.isNeedSort) {
        this.isNeedSort = false;
        this.sortIndex = null;
        this.startSort(sortField, this.order);
        return;
      }
      this.isNeedSort = true;
      this.sortIndex = index;
      this.startSort(sortField, this.order);
    },
  }
}
</script>

<style scoped>
.costing-contrast {
  font-size: 16px;
  background: #FEFDFF;
  padding: 10px;
}

</style>

<style>

.costing-contrast .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 0px solid #DCDFE6;
  box-sizing: border-box;
  color: #323233;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0px 15px 20px 20px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
  margin-bottom: -10px;
}

.costing-contrast .el-icon-arrow-up:before {
  content: ''
}

.costing-contrast .el-input__icon {
  height: 100%;
  width: 0px;
  text-align: center;
  transition: all .3s;
  line-height: 0px;
}

/*.controlHeight {*/
/*  height: 1000px;*/
/*  overflow: hidden;*/
/*  transition: 0.3s;*/
/*}*/


.normalHeight {
  height: 100%;
  transition: 0.3s;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 40px;
}


table {
  border-collapse: collapse;
  width: 98%;
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
}

tr {
  border-bottom: 1px solid #f1f1f1;
}

.cont {
  height: 40px;
}

.top {
  height: 40px;
  line-height: 40px;
  background: rgb(242, 245, 247);
}

.title {
  margin-right: 0px;
}

th {
  border-left: 0px solid #e9ebec;
  font-weight: 500;
}

.sum {
  color: #005dff;
  font-weight: bold;
}


.sort {
  display: inline-block;
  width: 7px;
  height: 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAeCAYAAAG012XDAAAABGdBTUEAALGPC/xhBQAAAppJREFUSA3dVjtvE0EQnjkbkCMRhBTIHwi/gdgSBQSIi1iOA3ZNT5HUQIfow6OnvwM/5BR2zKNAsvkP5A8kIEA4xALsW2b2POs9353jioKVzjP7zbezM7MvA1gNWXdrLWVhlhpvYTTeEuttNt2aLTaYSimvcc1zu90Mf9agQHVr+/cEdFjxaq0dAP9lIAGw2Xy/NBj++iysTPrcJdFPl0hJdImWtag9iXdEIE/qU6wpQ7ArNMlgbKawXlPNt8hQLZfyd8woUgzZbb7JwnDI84ZbOp2rFG72GJQYEhZwMi5UxwmcrIlnyS6JqbPWNdfpI3yJZRIu5TEJCtEu1alxup3Ohb29DxdlsEgdgnSUUg4cj76f/Pn5VetiIBkievU2J6ibrTNgiG6tfRJQJr82poluvf0JQEX3JmGBjVbLq7deKAX3J36iGiI+j6L/DEGv1n6sQD2aOSPiE9Qn8fBHpDShgcuLC04llxtQNa+EDHaHbMzRdawU8wfopEJHgrmMsY11szLl4q0qOrjLIDfWGQt6Mb98jsdnOWSN7MmQlTo62aP+O1BqFRA/wuXzN3Re00Srb8K0MKNSFLvA1WOH3FhSX+OGFVViI/UanS3ljzyiz5rUpyKW4+oTcuo2WiugoEffUnT+BITPPUJWVohZ2ul4k7ylvn15JnhJhHuwvLhm9gge9h8Q9WoifQ4DHbNVOOo/nIP6v1P0Qs1zNc1TCKrrs/Lm+rbZUvrlHf2m7aRW5nEQ4iAeZFJns4XCdf2sGKdCCt7IEW2vuJtXWCJxAOnUmrydBhVlWrqN/W3wfXO7TNvBcXYqxdtPIzgBkUhtEj9Jr+ptj/8CCE4Dqnc318tUP1+waTnTqZD5gYRjVVo4k2lsbFz7JniS/AspWgleJ+XDaAAAAABJRU5ErkJggg==);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}


.sortUp {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAeCAYAAAG012XDAAAABGdBTUEAALGPC/xhBQAAAqRJREFUSA3lVj1vE0EQnTljkFMQIRlRIDr4BRTJWUKQBEiKWE4Cdk1PkdRAh+gDpKe3Qz7kJiHEB0LyGck0tKSDICEQoAhijLGXmT3veffOd6c0NIxk7eybN7Ozs7O3BtAEpV5zhYZpanl9a5iF+UfwiaVrq8lkgkuW5me8JJlYrtcz/NOc+qrj3lKgJRWnsQQCngCPJAhOMwui81mxANOnfT1RQUqrTixbY7oq3y6BvGiPck35hOBGfINUao2nsoQ8BmSw3Revbej1eF1TLCsHV8ZcBj1y5GFofpP2ILAGR6rB3UUR5a5lzeX2Eb4MZRKuyhPKQy+Vcd5DIzWbo/Dq7amgzTt2hQphwUHnO3R+fgXWNTGXD5ZOK9PAy3EPtQCeqmEe0XHfUceFe5MxtpFQFzZWKJ/boWgm8Nic/tMZVta37wsQ92JXRXyA8iZ+OgiXRvc8c3LEKuVyLbohF3Tc0MnGHFnHUmFmD63UDYNAE8bYxrh/MsXCtTW0cFmRWWdMzUMj3+P+XTZsZlMYpv6k/j4D7f0and44IDbgxNlJyJ1rDaMqzE9TAcboNJbh14dDGZANHJjnjMfI8ExfugvQxQpFiVkUe5ASRbhsh+pjBnXenAfouJRRNiYR04RI9z5tw8RFeUJs9IJ6ddulYPrH03ROmiG6VO8prre3vfb+HbprY0l+CfZxaH+8m8D5H8zyoCobWytCQNKnKbEeiPioODe96LdUtepkW93f3E7UVkcUxL1M6ridz0/IZ8UPqsKUq89t+NPdpcYPf3kVyR+xBcdSU6X8Vfl2KjgUVBnKm88W6QGOvo6WtVQqXH+o+PoYGZRJgp6k1Y3tCv25WlBO5LB2c266SPXrKSw4xgZV5PLOzij8EPMj6czm7OylbwqPGv8Ccef3M+dWP3cAAAAASUVORK5CYII=);
}

.more {
  height: 40px;
  text-align: center;
  line-height: 40px;
}

span {
  display: inline-block;
}

span:active {
  transition: height 0.3s;
}

.oline {
  border-top: 40px #fff solid;
  /*上边框宽度等于表格第一行行高*/
  width: 0px;
  /*让容器宽度为0*/
  height: 0px;
  /*让容器高度为0*/
  border-left: 80px #ccc solid;
  /*左边框宽度等于表格第一行第一格宽度*/
  position: relative;
  /*让里面的两个子容器绝对定位*/

}

.span1 {
  font-style: normal;
  display: block;
  position: absolute;
  top: -47px;
  left: -38px;
  width: 35px;
}

.span2 {
  font-style: normal;
  display: block;
  position: absolute;
  top: -32px;
  left: -70px;
  width: 35x;
}

</style>