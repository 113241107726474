import request from '@/utils/request'

export function saveCosting(formFile) {
    return request({
        url: "/api/ajax/costing/saveCosting.json",
        method: 'post',
        data: formFile,
        enctype: 'multipart/form-data'
    })
}

export function saveCosting1(formFile) {
    return request({
        url: "/api/ajax/costing/saveCosting1.json",
        method: 'post',
        data: formFile,
        enctype: 'multipart/form-data'
    })
}

export function getDisCosting(formFile) {
    return request({
        url: "/api/ajax/costing/getDisCosting.json",
        method: 'post',
        data: formFile,
        enctype: 'multipart/form-data'
    })
}

export function getContrastCosting(formFile) {
    return request({
        url: "/api/ajax/costing/getContrastCosting.json",
        method: 'post',
        data: formFile,
        enctype: 'multipart/form-data'
    })
}



